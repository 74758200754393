.page{
    
    background-color:#000000;
    color: #FFFFFF;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
    min-height: 100vh;
}