@import url('./__box/popup__box.css');
@import url('./__close-btn/popup__close-btn.css');
@import url('./__container/popup__container.css');
@import url('./__container/popup__container_type_delete.css');
@import url('./__image-container/popup__image-container.css');
@import url('./__title/popup__title.css');
@import url('./__input/popup__input.css');
@import url('./__save-btn/popup__save-btn.css');
@import url('./__figure/popup__figure.css');
@import url('./__image/popup__image.css');
@import url('./__image-title/popup__image-title.css');
@import url('./__label/popup__label.css');
@import url('./__input/popup__input_type_error.css');
@import url('./__error/popup__error_visible.css');
@import url('./__save-btn/popup__save-btn_disabled.css');
@import url('./__info-message/popup__info-message.css');
@import url('./__info-image/popup__info-image.css');
.popup {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    top: 0;
    left: 0;
    text-align: center;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.5s, opacity 0.5s linear;
}

.popup::after {
    width: 100%;
    min-height: 100%;
    opacity: 0.5;
    background-color: #000000;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}