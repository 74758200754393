.popup__input {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
    min-width: 358px;
    font-size: 14px;
    line-height: 17px;
}

.popup__input:last-of-type {
    margin-bottom: 48px;
}

@media screen and (max-width:580px) {
    .popup__input {
        min-width: 238px;
    }
}