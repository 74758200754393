.logo{
    max-width: 173px;
    padding-bottom: 41px;
    
}

@media screen and (max-width: 768px) {
    .logo{
    padding-left: 40px;
    }
}

@media screen and (max-width: 425px){
    .logo{
        max-width: 126px;
        padding-bottom: 32px;
        padding-left: 27px;
    }
}