.profile__occupation{
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    grid-column: 1 / 3;

}

@media screen and (max-width: 580px){
    .profile__occupation{
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    }
}