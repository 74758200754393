.popup__container {
    background-color: #ffffff;
    width: 430px;
    border-radius: 10px;
}

@media screen and (max-width: 580px) {
    .popup__container {
        max-width: 282px;
        z-index: 2;
    }
}