.card__like-btn{
    grid-column: 2/3;
    grid-row: 2/2;
    align-self: center;
    justify-self: flex-end;
    margin-right: 18px;
    background-image: url('../../../images/heart.svg');
    background-repeat: no-repeat;
    border: none;
    width: 21px;
    height: 18px;
    cursor: pointer;
    margin-top: 25px;
    margin-bottom: 31px;
    background-color: rgba(0, 0, 0, 0);
}

.card__like-btn:hover{
    opacity: 0.6;
}