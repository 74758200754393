@import url('./__image/card__image.css');
@import url('./__heading/card__heading.css');
@import url('./__like-btn/card__like-btn.css');
@import url('./__like-btn/card__like-btn_active.css');
@import url('./__delete-btn/card__delete-btn.css');
@import url('./__like-count/card__like-count.css');
@import url('./__delete-btn/card__delete-btn_visible.css');
@import url('./__delete-btn/card__delete-btn_hidden.css');

.card{
    width: 100%;
    max-width: 282px;    
    background-color: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 50px;  
     
}