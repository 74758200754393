.footer__copyright{
    color: #545454;
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
}

@media screen and (max-width: 560px){
    .footer__copyright{
        font-size: 14px;
        line-height: 17px;
    }
}