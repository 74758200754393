
@import url('./__nav/header__nav.css');
@import url('./__link/header__link.css');
@import url('./__email/header__email.css');
.header{
    max-width: 880px;
    margin: 0 auto;
    padding-top: 45px;
    border-bottom: 1px solid rgb(84, 84, 84, .7); 
    display: flex;  
    justify-content: space-between;
    
}

