.header__link{
    text-decoration: none;
    font-size: 18px;
    line-height: 22px;    
    color: #fff;

}

.header__link:hover{
    opacity: 0.6;
}

@media screen and (max-width: 768px) {
    .header__link{
    padding-right: 40px;
    }
}

@media screen and (max-width: 325px){
    .header__link{
        max-width: 126px;
        padding-bottom: 32px;
        padding-left: 27px;
        font-size: 14px;
        line-height: 17px;
        padding-right: 0;
         
    }
}