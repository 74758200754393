@font-face{
    src:url('../fonts/Inter-Regular.woff') format('woff'),
    url('../fonts/Inter-Regular.woff2') format('woff2');
    font-family: 'Inter';
}

@font-face{
    src: url('../fonts/Inter-Black.woff') format('woff'),
    url('../fonts/Inter-Black.woff2') format('woff2');
    font-family: 'Inter';
    font-weight: 900;
}



@font-face{
    src: url('../fonts/Inter-Medium.woff') format('woff'),
    url('../fonts/Inter-Medium.woff2') format('woff2');
    font-family: 'Inter';
    font-weight: 500;
}