.popup__title{
    color: #000000;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    margin: 0 36px;
    padding-top: 34px;
    padding-bottom: 54px;
    text-align: left;
    
}

@media screen and (max-width: 320px){
    .popup__title{
        font-size: 18px;
        line-height: 22px;
    }
}