.popup__box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 495px;
}

@media screen and (max-width: 580px){
    .popup__box{
        width: auto;
}
}