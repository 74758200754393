.popup__info-message{    
        color:#000;
        font-weight: 900;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        margin: 32px auto 60px;    

}

@media screen and (max-width: 425px) {
      .popup__info-message{
              font-size: 20px;
              line-height: 24px;
              margin: 40px auto 50px;
      }  
}