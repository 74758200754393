.card__heading{
    color: #000000;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    margin: 0;
    grid-column: 1/2;
    align-self: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 21px;
    padding-top: 25px;
    padding-bottom: 31px;
}