@import url('./__avatar-container/profile__avatar-container.css');
@import url('./__avatar/profile__avatar.css');
@import url('./__edit-avatar/profile__edit-avatar.css');
@import url('./__info/profile__info.css');
@import url('./__name/profile__name.css');
@import url('./__occupation/profile__occupation.css');
@import url('./__edit-btn/profile__edit-btn.css');
@import url('./__add-btn/profile__add-btn.css');

.profile{
    display: flex;
    padding-top: 40px;
    max-width: 880px;
    margin: 0 auto;
    align-items: center;
  
}

@media screen and (max-width: 580px){
    .profile{
        max-width: 282px;
        width: calc(100% - 2*19px);;
        padding-top: 42px;
        flex-direction: column;
        
    }
}