@import url('./__copyright/footer__copyright.css');

.footer{
    max-width: 880px;
    
    margin: 0 auto;
    padding-top: 66px;
    padding-bottom: 60px;
}

@media screen and (max-width: 580px){
   .footer{
    width: calc(100% - 2*19px);
    padding-top: 48px;
    padding-bottom: 36px;
   }
}