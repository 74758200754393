.profile__avatar{
    border-radius: 50%;
    max-width: 120px;
    min-height: 120px;
    
}



@media screen and (max-width: 580px){
    .profile__avatar{
        margin: 0 auto;
    }
}