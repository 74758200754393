.cards{
    max-width: 880px;
    width: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(282px, 1fr));
    gap: 20px 17px;
    padding-top: 50px;
    margin: 0 auto;
}




@media screen and (max-width: 425px){
    .cards{
        padding-top: 36px;
        
        
    }
}