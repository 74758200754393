.profile__name{
    font-weight: 500;
    font-size: 42px;
    line-height: 1.14;
    margin: 0;    
    grid-column: 1 / 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
}

@media screen and (max-width: 580px){
    .profile__name{
        font-size: 22px;
        line-height: 27px;
        text-align: center;
    }
}