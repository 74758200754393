.profile__add-btn{
    background-color:rgba(0, 0, 0, 0);
    color: #ffffff;
    border: 2px solid #ffffff;
    background-size: 22px;
    width: 150px;
    height: 50px;
    margin-left: auto;
    background-image: url('../../../images/add.svg');
    background-repeat: no-repeat;
    background-position: center;
    
    cursor: pointer;
}

.profile__add-btn:hover{
    opacity: 0.6;
}

@media screen and (max-width: 580px){
    .profile__add-btn{
        margin: 36px 19px 0 19px;
        width: 100%;
        background-size: 16px;
    }
}